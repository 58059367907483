import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

const Timer = ({ timestamp }: { timestamp: number }) => {
  const [time, setTime] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      let countTimer = dayjs(timestamp).fromNow();
      setTime(countTimer);
    }, 1000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{time}</>;
};

export default Timer;
