import React, { useEffect, useState } from 'react';
import { ThemeProvider as BootstrapThemeProvider } from 'react-bootstrap';

type Theme = 'light' | 'dark';
type ThemeContext = {
  theme: Theme;
  toggle: () => void;
  mounted: boolean;
  prefixes?: Record<string, string>;
  breakpoints?: string[];
  minBreakpoint?: string;
  dir?: string;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ThemeContext = React.createContext<ThemeContext>({} as ThemeContext);

export type ThemeProvider = Partial<ThemeContext> & {
  children: React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ThemeProvider: React.FC<ThemeProvider> = ({ children, ...props }) => {
  const [theme, setTheme] = useState<Theme>('light');
  const [mounted, setMounted] = useState(false);

  const saveTheme = (theme: Theme) => {
    window.localStorage.setItem('theme', theme);
    setTheme(theme);
  };

  const toggle = () => {
    saveTheme(theme === 'light' ? 'dark' : 'light');
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme') as Theme;
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches && !localTheme ? saveTheme('dark') : localTheme ? setTheme(localTheme) : saveTheme('light');
    setMounted(true);
  }, []);

  useEffect(() => {
    document.documentElement.className = theme;
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggle, mounted, ...props }}>
      <ThemeContext.Consumer>{({ theme, toggle, mounted, ...props }) => <BootstrapThemeProvider {...props}>{children}</BootstrapThemeProvider>}</ThemeContext.Consumer>
    </ThemeContext.Provider>
  );
};
