import { useState, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ServiceApi from '../services/api';

const getTransactionsLocal = (): any[] => {
  return JSON.parse(localStorage.getItem('transactions') || '[]');
};

const setTransactionsLocal = (arr: any[]) => {
  localStorage.setItem('transactions', JSON.stringify(arr));
};

export const useSubmitForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [transactions, setTransactions] = useState(getTransactionsLocal());
  const [address, setAddress] = useState('');
  const [messageSuccess, setMessageSuccess] = useState('');
  const [messageError, setMessageError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isInValid, setIsInValid] = useState(false);

  const checkTransaction = async (transaction: any) => {
    let locals = getTransactionsLocal();
    let index = 0;
    let existing = locals.find((t: any, i) => {
      if (t.requestId === transaction.requestId) {
        index = i;
        return true;
      }
      return false;
    });
    if (!existing) {
      locals.unshift(transaction);
      if (locals.length > 10) {
        locals.pop();
      }
    } else {
      locals.splice(index, 1, transaction);
    }
    setTransactionsLocal(locals);
    setTransactions(locals);

    setAddress('');
    setMessageSuccess('Faucet request was submitted successfully.');
    setMessageError('');

    if (['success', 'failed'].includes(transaction.status)) {
      setMessageSuccess('Faucet request transaction has been successfully confirmed.');
      setMessageError('');
    } else {
      const { data: _data, error: _error } = await ServiceApi.getFaucetTransaction(transaction.requestId);
      if (_error) {
        setIsLoading(false);
        setMessageError('Could not get transaction status.');
        return;
      }
      setTimeout(() => checkTransaction(_data), 1000);
    }
  };

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    setIsInValid(false);
    setMessageSuccess('');
    setMessageError('');

    if (!executeRecaptcha) return;

    if (!/^shareledger[a-zA-Z0-9]+$/.test(address)) {
      setIsLoading(false);
      setIsInValid(true);
      return;
    }

    const token = await executeRecaptcha('submit');
    const { data, error } = await ServiceApi.createFaucetTransaction({
      address,
      token,
    });

    setIsLoading(false);
    if (error) {
      setAddress('');
      setMessageSuccess('');
      setMessageError(error);
    } else {
      checkTransaction(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeRecaptcha, address]);

  return {
    isLoading,
    isInValid,
    address,
    messageError,
    messageSuccess,
    transactions,
    setAddress,
    handleSubmit,
    setMessageError,
    setMessageSuccess,
  };
};
