import { axiosInstance } from './axiosInstance';

const ServiceApi = {
  async baseApi(sub_url: string, method: string, params?: any, data?: any) {
    try {
      const response = await axiosInstance().request({
        url: sub_url,
        method,
        params,
        data,
      });

      const responseData = await response.data;

      return {
        data: responseData.data,
        error: null,
      };
    } catch (error: any) {
      return {
        data: null,
        error: error.response.data?.message,
      };
    }
  },
  createFaucetTransaction(data: { address: string; token: string }) {
    return this.baseApi('/requests', 'POST', undefined, data);
  },
  getFaucetTransaction(id: string) {
    return this.baseApi(`/requests/${id}`, 'GET');
  },
  checkStatus() {
    return this.baseApi('/status', 'GET');
  },
};

export default ServiceApi;
