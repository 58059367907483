import { DependencyList, useEffect, useRef } from 'react';

export function useTimeout(callback: Function, delay: number) {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
    return () => clearTimeout(timeoutRef.current);
  }, [delay]);

  return timeoutRef;
}

export function usePoll(callback: () => void | Promise<void>, deps?: DependencyList, interval: number = 1000) {
  if (typeof callback !== 'function') throw new TypeError("Can't poll without a callback function");

  return useEffect(() => {
    let killed = false;

    async function poll() {
      if (killed) return;
      await callback();
      setTimeout(poll, interval);
    }

    poll();

    return () => {
      killed = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
