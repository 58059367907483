import { useEffect } from 'react';
import { Alert, Table } from 'react-bootstrap';
import { useSubmitForm } from '../../hooks';
import Status from '../Status';
import Timer from '../Timer';
import './Form.scss';

const Form = () => {
  const { address, setAddress, handleSubmit, messageError, messageSuccess, transactions, isLoading, isInValid } = useSubmitForm();

  useEffect(() => {
    let alertNode = document.querySelector('.alert');
    alertNode?.classList.remove('d-none');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const CloseAlert = () => {
    let alertNode = document.querySelector('.alert');
    alertNode?.classList.add('d-none');
  };

  return (
    <form
      className='p-4 p-sm-5 border rounded-3 bg-light'
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      {(messageSuccess || messageError) && !isLoading && (
        <Alert variant={`${messageSuccess ? 'success' : 'danger'}`} dismissible onClose={CloseAlert}>
          {messageSuccess || messageError}
        </Alert>
      )}
      <div className='form-floating mb-3'>
        <input
          type='text'
          className={`form-control ${isInValid ? 'is-invalid' : ''}`}
          id='floatingInput'
          placeholder='shareledger'
          value={address}
          disabled={isLoading}
          onChange={(e) => setAddress(e.target.value)}
        />
        <label>Shareledger address</label>
        {isInValid && <div className='invalid-feedback'>Please provide a valid address.</div>}
      </div>
      <button className='w-100 btn btn-lg btn-primary mb-3' type='submit' disabled={isLoading}>
        {isLoading ? (
          <div className='spinner-border text-light spinner-border-sm' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        ) : (
          'Send me SHR'
        )}
      </button>
      {!!transactions.length && (
        <div>
          <p>
            <strong>Last {transactions.length} transactions</strong>
          </p>
          <Table striped size='sm' responsive>
            <thead>
              <tr>
                <th>Transaction hash</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {transactions?.length ? (
                transactions.map((trans: any) => (
                  <tr key={trans.id}>
                    <td className='text-nowrap align-middle d-flex flex-row align-items-center'>
                      <span className='me-2'>
                        <Status status={trans.status} />
                      </span>
                      <a className='d-inline-block text-truncate text-width' href={`${process.env.REACT_APP_EXPLORER_URL}/transactions/${trans.transactionHash}`} target='_blank' rel='noreferrer'>
                        {trans.transactionHash || `(Pending transaction hash)`}
                      </a>
                    </td>
                    <td className='text-nowrap'>
                      <Timer timestamp={trans.timestamp} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>-</td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      )}
    </form>
  );
};

export default Form;
