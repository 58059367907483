import classnames from 'classnames';
import React from 'react';
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { ReactComponent as Logoiw } from '../../assets/images/logoi-w.svg';
import { ReactComponent as Logoi } from '../../assets/images/logoi.svg';
import { useTheme } from '../../hooks';
import './Footer.scss';

const ToggleDarkModeButton = () => {
  const { theme, toggle } = useTheme();

  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    toggle();
  };

  return (
    <>
      <OverlayTrigger placement='top' overlay={(props) => <Tooltip {...props}>{`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}</Tooltip>}>
        <a href='#' onClick={onClick}>
          {theme === 'light' ? <Icon.MoonStarsFill className='text-primary' /> : <Icon.SunFill className='text-warning' />}
        </a>
      </OverlayTrigger>
    </>
  );
};

const Footer = ({ className }: React.HTMLAttributes<HTMLElement>) => {
  const { theme } = useTheme();

  const socialLinks = [
    { name: 'linkedin', url: 'https://linkedin.com/company/sharering-network', icon: <Icon.Linkedin /> },
    { name: 'twitter', url: 'https://twitter.com/ShareRingGlobal', icon: <Icon.Twitter /> },
    { name: 'facebook', url: 'https://www.facebook.com/ShareRing.Network', icon: <Icon.Facebook /> },
    { name: 'telegram', url: 'https://t.me/ShareRing', icon: <Icon.Telegram /> },
    { name: 'github', url: 'https://github.com/ShareRing/Shareledger', icon: <Icon.Github /> },
  ];

  return (
    <footer className={classnames('d-flex flex-wrap justify-content-between align-items-center py-3 border-top', className)}>
      <Col md='4' className='d-flex align-items-center text-muted align-center justify-content-start'>
        {theme === 'dark' ? <Logoiw height='1em' width='1em' /> : <Logoi height='1em' width='1em' />}
        <span className='text-primary'>&nbsp;ShareRing&nbsp;</span>2022
      </Col>
      <Col as='ul' md='4' className='social-links nav justify-content-end list-unstyled d-flex'>
        {socialLinks.map((e) => (
          <li key={e.name} className='ms-3'>
            <a className='text-muted' href={e.url} target='_blank' rel='noreferrer'>
              {e.icon}
            </a>
          </li>
        ))}
        <li className='ms-4'>
          <ToggleDarkModeButton />
        </li>
      </Col>
    </footer>
  );
};

export default Footer;
