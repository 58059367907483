import { useState } from 'react';
import { Button, ButtonProps, Modal, ModalProps } from 'react-bootstrap';

const Faqs = ({ buttonProps, modalProps }: { buttonProps?: ButtonProps; modalProps?: ModalProps }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const donationAddress = process.env.REACT_APP_DONATION_ADDRESS;

  return (
    <>
      <Button onClick={handleShow} {...buttonProps}>
        Have questions?
      </Button>
      <Modal show={show} onHide={handleClose} size='lg' centered {...modalProps}>
        <Modal.Header closeButton>
          <Modal.Title>Frequently Asked Questions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>How do I use this?</h6>
          <p>To request funds, simply enter your shareledger address and hit 'Send me SHR'.</p>
          <h6>How does it work?</h6>
          <p>You can request 100 SHR every 24h without any authentication.</p>
          <h6>What is a testnet ShareRing faucet?</h6>
          <p>
            A ShareRing faucet is a developer tool to get testnet SHR in order to test and troubleshoot your decentralized application or protocol before going live on Shareledger mainnet, where one
            must use real SHR.
          </p>
          <h6>What is a testnet token?</h6>
          <p>Testnet tokens are a test currency that allows you to test your Shareledger dApps before going live on mainnet.</p>
          <h6>What to do with my SHR testnet tokens?</h6>
          <p>Spend it on Shareledger testnet e.g. stake, swap or deploy and run your own dApps. If you have testnet SHRs that are no longer in use, you can always send back to us at</p>
          <code>{donationAddress}</code>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Faqs;
