import React from 'react';
import * as Icon from 'react-bootstrap-icons';

const colorStatus = {
  pending: 'text-secondary',
  ready: 'text-warning',
  failed: 'text-danger',
  success: 'text-success',
  processing: 'text-info',
};

const Status = ({ status }: { status: keyof typeof colorStatus | 'processing' }) => {
  return ['pending', 'ready', 'processing'].includes(status) ? (
    <div className={`spinner-border ${colorStatus[status]} spinner-border-sm`} role='status'>
      <span className='visually-hidden'>Loading...</span>
    </div>
  ) : (
    <Icon.CircleFill className={colorStatus[status]} />
  );
};

export default Status;
