import numbro from 'numbro';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import './App.scss';
import { ReactComponent as Logoi } from './assets/images/logoi.svg';
import { ReactComponent as Logoiw } from './assets/images/logoi-w.svg';
import Faqs from './components/Faqs';
import Footer from './components/Footer';
import Form from './components/Form';
import { usePoll, useTheme } from './hooks';
import ServiceApi from './services/api';

const App = () => {
  const [status, setStatus] = useState(true);
  const [balances, setBalances] = useState<{ amount: string; denom: string }[]>([]);

  const { theme } = useTheme();

  usePoll(
    async () => {
      const { data } = await ServiceApi.checkStatus();
      if (!data) return;
      if (data.balances !== balances) {
        data.balances = data.balances.map((item: { denom: string; amount: string }) =>
          item.denom === 'nshr'
            ? {
                denom: 'shr',
                amount: numbro(item.amount)
                  .divide(10 ** 9)
                  .format(),
              }
            : item
        );
        setBalances(data.balances || []);
      }
      if (data.status !== status) {
        setStatus(data.status);
      }
    },
    [],
    5000
  );

  return (
    <Container className='p-4 min-vh-100 d-flex flex-column justify-content-between'>
      <Row className='align-items-center g-lg-5 py-5 flex-lg-grow-1'>
        <Col lg='5' className='text-center text-lg-start position-relative'>
          <h1 className='display-5 fw-bold mb-3'>
            <span className='d-lg-none'>
              {theme === 'dark' ? <Logoiw width='1em' height='1em' /> : <Logoi width='1em' height='1em' />}
              <span className='text-primary fw-normal'>&nbsp;ShareRing&nbsp;</span>
            </span>
            Faucet
          </h1>
          <p className='col-lg-10 fs-4'>Fast and reliable. 100 SHR per day.</p>
          <div className='logo position-absolute d-none d-lg-block'>{theme === 'dark' ? <Logoiw /> : <Logoi />}</div>
        </Col>
        <Col md='10' lg='7' className='mx-auto'>
          <Form />
          <Row>
            <Col>
              <Faqs buttonProps={{ variant: 'link', className: 'ps-0' }} />
            </Col>
            <Col className='col-auto'>
              <ul className='status list-unstyled font-monospace text-end text-muted my-2'>
                <li>
                  Faucet health: <Icon.CircleFill className={`text-${status ? 'success' : 'danger'}`} />
                  {`${status ? ' Good' : ' Bad'}`}
                </li>
                <li>
                  {!!balances.length && 'Balance: ' + balances.map((b) => `${numbro(b.amount).format({ average: true, mantissa: 2, thousandSeparated: true })} ${b.denom.toUpperCase()}`).join(', ')}
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default App;
